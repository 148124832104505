<template>
  <div>{{ details }}</div>
</template>

<script>
import AxiosInstance from "@/core/services/axios.service";

export default {
  name: "LeadTracker",
  data() {
    return {
      details: null,
    };
  },
  async created() {
    await AxiosInstance.post("tracker", {
      token: this.$route.params.token,
    }).then(({ data }) => {
      this.details = data;
    });
  },
};
</script>

<style scoped></style>
